.header {
  height: 60px;
  display: flex;
  align-items: center;
  position: sticky;
  background-color: #131921;
  top: 0;
  z-index: 100;
}

.header__logo {
  width: 100px;
  object-fit: contain;
  margin: 0 20px;
  margin-top: 18px;
}

.header__search {
  display: flex;
  flex: 1;
  align-items: center;
  border-radius: 24px;
}

.header__searchInput {
  height: 12px;
  width: 100%;
  border: none;
  padding: 10px;
}

.header__searchIcon {
  padding: 5px;
  height: 22px;
  background-color: #cd9042;
}

.header__optionLineOne {
  font-size: 10px;
}

.header__optionLineTwo {
  font-size: 13px;
  font-weight: 800;
}

.header__nav {
  display: flex;
  justify-content: space-evenly;
}

.header__option {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  color: white;
}

.header__optionBasket {
  display: flex;
  align-items: center;
  color: white;
}

.header__backetCount {
  margin-left: 10px;
  margin-right: 10px;
}
